<script lang="ts" setup>
const { t } = useT();
const { open } = useAppModals();
const { seasonInitData } = useSeasonsData({ immediate: false });

const handleOpenModal = () => {
	if (seasonInitData.value?.isAvailable) {
		open("LazyOModalSeasonGames");
		return;
	}

	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>

<template>
	<div class="wrapper">
		<NuxtImg class="image" src="/nuxt-img/season/banner.webp" alt="quest" width="527" height="690" loading="lazy" />

		<div class="top">
			<div>
				<AText as="div" variant="tonk" :modifiers="['capitalize', 'bold']" class="text-tlalnepantla label">
					{{ t("Power Tower") }}
				</AText>
				<AText
					as="div"
					variant="tlalpan"
					:modifiers="['underline', 'medium']"
					class="reward text-caracas"
					@click="open('LazyOModalSeason')"
				>
					{{ t("Open") }}
				</AText>
			</div>

			<AButton variant="primary" class="btn" size="lg" @click="handleOpenModal">
				<AText variant="toledo" :modifiers="['semibold']" class="text-tlalnepantla">
					{{ seasonInitData?.isAvailable ? t("Play") : t("Unlock Tower") }}
				</AText>
				<NuxtIcon v-if="!seasonInitData?.isAvailable" name="20/ticket" filled class="icon-ticket" />
			</AButton>
		</div>
		<div class="bottom">
			<OSeasonLevelProgress />
		</div>
	</div>
</template>
<style lang="scss" scoped>
.wrapper {
	width: 552px;
	height: 228px;
	border-radius: 16px;
	background: var(--gosaba);
	display: flex;
	flex-direction: column;
	padding: 24px 16px 56px 204px;
	position: relative;
	overflow: hidden;

	@include media-breakpoint-up(md) {
		&::before {
			content: "";
			background: var(--gondal);
			position: absolute;
			z-index: 1;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 50px;
		}
	}

	@include media-breakpoint-down(xl) {
		padding: 24px 16px 56px 152px;
	}

	@include media-breakpoint-down(lg) {
		width: 100%;
	}

	@include media-breakpoint-down(md) {
		height: 158px;
		padding: 16px 16px 16px 104px;
		overflow: hidden;
	}
}

.top {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 8px;
	z-index: 1;
}

.label {
	text-shadow: 0 0 6px var(--carapicuiba);
	line-height: 24px;
}

.reward:hover {
	cursor: pointer;
	text-decoration: none;
}

.blocked {
	margin-top: 30px;

	@include media-breakpoint-down(sm) {
		margin-top: 8px;
	}
}

.bottom {
	margin-top: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.image {
	position: absolute;
	left: 0;
	top: 0;
	width: 175px;
	height: 230px;
	object-fit: cover;
	z-index: 2;

	@include media-breakpoint-down(xl) {
		width: 174px;
		height: 228px;
		left: -20px;
	}

	@include media-breakpoint-down(md) {
		width: 122px;
		height: 160px;
	}
}

.quest-link {
	margin: 0 auto;

	&:hover {
		cursor: pointer;
	}
}

.description {
	margin-top: 8px;
}

.btn {
	width: max-content;
	padding: 8px;
	min-width: 120px;

	@include media-breakpoint-down(md) {
		padding: 8px;
		min-width: 80px;
	}
}

.icon-ticket {
	font-size: 24px;
	margin-left: 4px;
}
</style>
